import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

class Menu extends Component {
    render(){
        return(
            <div className="menu-frame">
                <ul className="menu">
                    <li className="menu-item"><NavLink to="/" >SITRAKA</NavLink></li>
                    <li className="menu-item"><NavLink to="/about" >ABOUT</NavLink></li>
                    <li className="menu-item"><a href="https://vvfa.space"  rel="noopener noreferrer" target="_blank" >VVFA</a></li>
                </ul>
            </div>
        );
    }
}

export default Menu;