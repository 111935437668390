import React, { Component } from 'react';
import { connect } from 'react-redux';
import FadeInSection from '../utils/fadeInSection';

class Project extends Component {

    constructor(props){
        super(props);
        this.state = {
            bool: false,
            viz: true,
            info: Number(this.props.info),
            isInfoOpen: false
        };
        this.setInfo(this.props.info);
        this.scrollTop = React.createRef();
        
    }
    setInfo(data){
        this.props.getInfo(data);
    }
    toggle = () => {
        this.setState(prevState => ({
            bool: !prevState.bool
        }));
        console.log("tog");
        console.log(this.state.bool);
    }

    scroll() {
        this.scrollTop.current.scrollIntoView({ behavior:'smooth', block:'start'});
    }

    

    componentDidUpdate(){
        this.scroll();
    }

    render (){
        return(
        <div className="project-container">
            <ul className="project-img-list" onChange={this.handleChange}>
                <li ref={this.scrollTop}></li>
                {this.props.pages[this.props.info].files.map((value, index) => {
                    if (value[0].includes("https")){
                        return  <FadeInSection key={index}>
                               <li onClick={this.giveProp} className="project-items" >
                                        <div className="project-vid-container" /*style={{backgroundImage: "url(" + window.location.origin + this.dir + "/" + value[0] + ")" }}*/ >
                                            <iframe title={index} src={value[0]} width="75%" height="100%" frameBorder="0" allow="autoplay;fullscreen" allowFullScreen align="center"></iframe>
                                            
                                        </div>
                                        <div className="project-captions">{value[1]}</div>
                                    </li>
                               </FadeInSection>
                    }else{
                        return   <FadeInSection key={index}>
                              <li className="project-items" >
                                        <div className="project-img-container" /*style={{backgroundImage: "url(" + window.location.origin + this.dir + "/" + value[0] + ")" }}*/ >
                                            <img alt="" src={window.location.origin + this.props.pages[this.props.info].dir + "/" + value[0]} />
                                            
                                        </div>
                                        <div className="project-captions">{value[1]}</div>
                                    </li>
                                </FadeInSection>
                    }
                      
                })}       
            </ul>

        </div>
        );
    
     }
}

function mapStateToProps(state){
    return {
        pages: state.Pages
    };
}

export default connect(mapStateToProps)(Project);