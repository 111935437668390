export default function() {
    return[
        {
            id: 0, 
            title: "Designer of things", 
            img: "/assets/Thumbs/vvfa_thumb.jpg",
            clientId: "client_id=aae0cd3ce269784234bb78aa6d485394",
            url: "/vvfa",
            content: "I was born in Madagascar and grew up in Paris, then moved to London in 2008 to study at the Royal College of Art, in the Design Interactions program. \n \nI am an independent artist/designer with an interest in speculative design and world-building. I use designed objects, artefacts and things to build narratives, to probe, imagine and excite. I use design as an exploratory tool to examine and critique dominant socio-technical imaginaries, and explore new relationships between people and technology. \n \nMy practice is eclectic and relies on a methodological openness to imagine projects beyond any particular framework, craft or sense of aesthetic. More recently, I have been running a public facing research project, called Very Very Far Away (VVFA), concerned with democratising the future; a future often depicted as a monocultural narrative construct in need of diversity and re-imagining. \n \nWith a focus on space exploration and deep time horizons as narrative devices, VVFA uses world-building as a way of collectively thinking critically and reflecting on the present status quo, whilst using fiction to reveal more about our current prejudices and attitudes.\n \nI am also an Associate Lecturer in the Narrative Environment course at Central Saint Martins, University of the Arts London.\n \ne: mail [at] sitraka [dot] co [dot] uk\nm:+447530204568\n\nSugarhouse Studios, Unit 4\n19 Collett Rd.\nSE16 4DJ, London"
        },
        {
            id: 1, 
            title: "Upcoming & Current", 
            content: "Lecturer\nBSc Creative Computing\nUniversity for the Creative Arts\nCanterbury, UK"
        },
        {
            id: 2,
            title: "EDUCATION",
            content:"MA(RCA) Design Interactions\nRoyal College of Art\nLondon, UK\n2010\n\nMA Graphic Design & Multimedia\nENSAD\nParis, FR\n2008\n\nBA Industrial Design\nENSAAMA Olivier-De-Serres\nParis, FR\n2004"
        },
        {
            id: 3,
            title: "TEACHING/WORKSHOP",
            content: [
                {
                    yr:"2020-present",
                    positions: [
                        {
                            title: "Associate Lecturer",
                            dept: "MA Narrative Environment",
                            institution: "Central Saint Martins, University of the Arts London",
                            location: "London, UK"
                        },
                        {
                            title: "Associate Lecturer",
                            dept: "Bsc Creative Computing",
                            institution: "University for the Creative Arts",
                            location: "Canterbury, UK"
                        }
                    ]
                },
                {
                    yr:"2021",
                    positions: [
                        {
                            title: "Associate Lecturer",
                            dept: "MA Industrial Design",
                            institution: "Central Saint Martins, University of the Arts London",
                            location: "Lonon, UK"
                        }
                    ]
                },
                {
                    yr:"2020",
                    positions: [
                        {
                            title: "Visiting Lecturer",
                            dept: "Critical Practices",
                            institution: "Royal College of Art",
                            location: "London, UK"
                        },
                        {
                            title: "Visiting Lecturer",
                            dept: "Law LLB",
                            institution: "University of Westminster",
                            location: "Londond, UK"
                        }
                    ]
                },
                {
                    yr: "2019",
                    positions: [
                        {
                            title: "Visiting Lecturer",
                            dept: "Digital Direction",
                            institution: "Royal College of Art",
                            location: "Londond, UK"
                        },
                        {
                            title: "Visiting Lecturer",
                            dept: "BA Design",
                            institution: "Goldsmiths University",
                            location: "Londond, UK"
                        },
                        {
                            title: "Workshop Facilitator",
                            dept: "Made In China",
                            institution: "School of Machines, Making and Make Believe",
                            location: "Shanghai, Shenzhen, Hong Kong, CN"
                        },
                        {
                            title: "Workshop Facilitator",
                            dept: "Made In China",
                            institution: "New York University Shanghai",
                            location: "Shanghai, CN"
                        },
                        {
                            title: "Visiting Lecturer",
                            dept: "Media Design Practices",
                            institution: "Art Center College of Design",
                            location: "Pasadena, CA, USA"
                        },
                        {
                            title: "Visiting Lecturer",
                            dept: "Space and Communication",
                            institution: "HEAD - Geneve",
                            location: "Geneva, CH"
                        },
                        {
                            title: "Workshop Facilitator",
                            dept: "Speculative Design at Fjord",
                            institution: "Accenture",
                            location: "Paris, FR"
                        }
                    ]
                },
                {
                    yr: "2016-2019",
                    positions: [
                        {
                            title: "Sessional Lecturer",
                            dept: "Postgraduates",
                            institution: "Ravensbourne",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2018",
                    positions: [
                        {
                            title: "Visiting Artist",
                            dept: "AIADO",
                            institution: "School of the Art Institute of Chicago",
                            location: "Chicago, IL, USA"
                        }
                    ]
                },
                {
                    yr: "2017",
                    positions: [
                        {
                            title: "Workshop Facilitator",
                            dept: "Default 17",
                            institution: "Ramdom Association / Last Station",
                            location: "Gagliano Del Capo, IT"
                        }
                    ]
                },
                {
                    yr: "2015-2017",
                    positions: [
                        {
                            title: "Workshop Facilitator",
                            dept: "Fabricating Fictions",
                            institution: "Space Studios, Art & Technology",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2016",
                    positions: [
                        {
                            title: "Visiting Lecturer",
                            dept: "Masterstudio",
                            institution: "FHNW Institut Integrative Gestaltung",
                            location: "Basel, CH"
                        },
                        {
                            title: "Workshop Facilitator",
                            dept: "Coming Soon",
                            institution: "School of Machines, Making and Make Believe w/ Casa Jasmina & Officine Arduino",
                            location: "Turin, IT"
                        },
                    ]
                },
                {
                    yr: "2015",
                    positions: [
                        {
                            title: "Workshop Facilitator",
                            dept: "Fabricating Empathy",
                            institution: "School of Machines, Making and Make Believe",
                            location: "Berlin, DE"
                        },
                        {
                            title: "Artist in Residence",
                            dept: "CoLab",
                            institution: "Space Studios, Art & Technology",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2013-2014",
                    positions: [
                        {
                            title: "Visiting Lecturer",
                            dept: "Biotechnologies, les formes futures de soins",
                            institution: "ENSCI-les-Ateliers w/ La Paillasse",
                            location: "Paris, FR"
                        }
                    ]
                },
                {
                    yr: "2013",
                    positions: [
                        {
                            title: "Visiting Researcher",
                            dept: "Media Design Practices",
                            institution: "Art Center College of Design",
                            location:"Pasadena, CA, USA"
                        }
                    ]
                }
            ]
        },
        {
            id: 4,
            title: "EXHIBITIONS/TALKS",
            content: [
                {
                    yr: "2021",
                    positions: [
                        {
                            title: "Human Considerations: Speculations on Lunar Habitation",
                            dept: "NSPDOS",
                            institution: "Italian Virtual Pavillion, Venice Biennale",
                            location: "Venice, IT"
                        }
                    ]
                },
                {
                    yr: "2020",
                    positions: [
                        {
                            title: "Boots on Mars",
                            dept: "How To show Time in Monochrome",
                            institution: "Manifesta 13 w/ Diaspore",
                            location: "Marseille, FR"
                        },
                        {
                            title: "To The Unknown",
                            dept: "The Body Series",
                            institution: "Somerset House Studios",
                            location: "London, UK"
                        },
                        {
                            title: "A More Familiar Unknown",
                            dept: "From Tomorrow",
                            institution: "Tate Britain",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2019",
                    positions: [
                        {
                            title: "A Familiar Unknown",
                            dept: "Diaspore - Forest Rangers",
                            institution: "Jupiter Woods",
                            location: "London, UK"
                        },
                        {
                            title: "Re-imagining Outer Space",
                            dept: "70th International Astronautical Congress, Space Culture Symposium",
                            institution: "Walter E. Washington Convention Center",
                            location: "Washington D.C., USA"
                        },
                        {
                            title: "Alive In Space",
                            dept: "70th International Astronautical Congress, GNF Session",
                            institution: "Walter E. Washington Convention Center",
                            location: "Washington D.C., USA"
                        },
                        {
                            title: "Future of Design",
                            dept: "Spotify Design Days 2019",
                            institution: "Zmar Eco",
                            location: "Alentejo, PT"
                        },
                        {
                            title: "Crafting Narratives",
                            dept: "Talk Series",
                            institution: "SKD Art Education",
                            location: "Shanghai, CN"
                        },
                        {
                            title: "Objects as Narratives",
                            dept: "Speak My Language",
                            institution: "Dublab",
                            location: "Los Angeles, CA, USA"
                        }
                    ]
                },
                {
                    yr: "2018",
                    positions: [
                        {
                            title: "VVFA - Radio",
                            dept: "Sugarhouse Open Studios",
                            institution: "London Design Festival",
                            location: "London, UK"
                        },
                        {
                            title: "World Building",
                            dept: "Imagine If Festival",
                            institution: "Tate Britain",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2017",
                    positions: [
                        {
                            title: "VVFA - Democratising Future Narratives",
                            dept: "68th International Astronautical Congress, Space Culture Symposium",
                            institution: "Adelaide Convention Centre",
                            location: "Adelaide, AU"
                        },
                        {
                            title: "VVFA - Radio",
                            dept: "Digital Design Weekend",
                            institution: "Victoria and Albert museum",
                            location: "Lodnon, UK"
                        },
                        {
                            title: "New Cultural Fictions",
                            dept: "Fine Art Talk, London Festival of Architecture",
                            institution: "StudioRCA",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2016",
                    positions: [
                        {
                            title: "Reverse Archaeology, Crafting Narratives through Designed Artefacts",
                            dept: "Master Talk, Masterstudio",
                            institution: "FHNW Institut Integrative Gestaltung",
                            location: "Basel, CH"
                        },
                        {
                            title: "Crafting Narratives",
                            dept: "Connected Devices, Design Fictions & Future Possibilities",
                            institution: "Casa Jasmina / Officine Arduino",
                            location: "Turin, IT"
                        }
                    ]
                },
                {
                    yr: "2015",
                    positions: [
                        {
                            title: "On Speculative Design",
                            dept: "Materials Unbound: Future Identities",
                            institution: "Fashion Space Gallery",
                            location: "London, UK"
                        },
                        {
                            title: "Crafting Narratives",
                            dept: "Resonate 15",
                            institution: "Kolarac",
                            location: "Belgrade, RS"
                        }
                    ]
                },
                {
                    yr: "2014",
                    positions: [
                        {
                            title: "Time Conditioning",
                            dept: "Digital Design Weekend",
                            institution: "Victoria and Albert museum",
                            location: "London, UK"
                        },
                        {
                            title: "Photon Shower",
                            dept: "D&AD, Yellow Pencil Award",
                            institution: "Battersea Evolution",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2013",
                    positions: [
                        {
                            title: "Prophecy Program",
                            dept: "This Happened",
                            institution: "Victoria and Albert museum",
                            location: "London, UK"
                        },
                        {
                            title: "Prophecy Program",
                            dept: "Media Design Practices - Summer Research Exhibition",
                            institution: "Art Center College of Design",
                            location: "Pasadena, CA, USA"
                        },
                        {
                            title: "Prophecy Program",
                            dept: "Artists in Laboratories, episode #39",
                            institution: "Resonance FM",
                            location: "London, UK"
                        },
                        {
                            title: "Beam Me Down",
                            dept: "Dream Team, St Etienne Design Biennale",
                            institution: "Cité du Design",
                            location: "Saint Etienne, FR"
                        }
                    ]
                },
                {
                    yr: "2012",
                    positions: [
                        {
                            title: "Fish Drop",
                            dept: "Les Cascades de l’Infraréel",
                            institution: "XPO Gallery",
                            location: "Paris, FR"
                        },
                        {
                            title: "Design and Speculations",
                            dept: "Ampera Technology Week",
                            institution: "KXFS",
                            location: "London, UK"
                        },
                        {
                            title: "Workers Helmet",
                            dept: "Art Relay",
                            institution: "KK Outlet",
                            location: "London, UK"
                        },
                        {
                            title: "Bodybuilding, Architectures for the Body",
                            dept: "Surface Design Show",
                            institution: "Business Design Centre",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2011",
                    positions: [
                        {
                            title: "Time Conditioning",
                            dept: "Design Faction",
                            institution: "Lodz Design Festival",
                            location: "Lodz, PL"
                        },
                        {
                            title: "Shocking",
                            dept: "See Yourself Sensing",
                            institution: "Work Gallery",
                            location: "London, UK"
                        },
                        {
                            title: "Glitch Fiction",
                            dept: "Paris Design Week",
                            institution: "La Cité de la Mode et du Design",
                            location: "Paris, FR"
                        }
                    ]
                },
                {
                    yr: "2010",
                    positions: [
                        {
                            title: "De l’Objet a la Ville",
                            dept: "Venice Biennale of Architecture",
                            institution: "Istituto Veneto di Scienze, Lettere ed Arti",
                            location: "Venice, IT"
                        },
                        {
                            title: "Shocking",
                            dept: "Impact! - Cheltenham Science Festival",
                            institution: "Montpellier Gardens",
                            location: "Cheltenham, UK"
                        },
                        {
                            title: "Shocking",
                            dept: "Impact!",
                            institution: "Royal College of Art",
                            location: "London, UK"
                        }
                    ]
                }
            ]
        },
        {
            id: 5,
            title: "EXPERIENCE",
            content: [
                {
                    yr: "2006-present",
                    positions: [
                        {
                            title: "Independent Artist and Designer",
                            institution: "sitraka.co.uk",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2013-2017",
                    positions: [
                        {
                            title: "Director",
                            institution: "Nexus Studio",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2010-2011",
                    positions: [
                        {
                            title: "Creative Technologist",
                            institution: "Wieden and Kennedy",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2009",
                    positions: [
                        {
                            title: "Interaction Designer", 
                            institution: "Philips Design Probes",
                            location: "Eindhover, NL"
                        }
                    ]
                },
                {
                    yr: "2007",
                    positions: [
                        {
                            title: "Art Director",
                            institution: "Studio Zero",
                            location: "Shanghai, CN"
                        }
                    ]
                }
            ]
        },
        {
            id: 6,
            title: "PUBLICATIONS",
            content: [
                {
                    yr: "2019",
                    positions: [
                        {
                            title: "Exploring ways to democratise the narratives that make up tomorrow’s visions",
                            dept: "Ivica Mitrovic",
                            institution: "SpeculativeEdu.eu",
                            location: "Split, HR"
                        },
                        {
                            title: "¡Meow!",
                            dept: "Rachel Uwa",
                            institution: "Schoolofma.org",
                            location: "Berlin, DE"
                        },
                        {
                            title: "Virtual Futures: Near-Future Fictions vol. 1",
                            dept: "Dan O'Hara, Tom Ward, Stephen Oram",
                            institution: "Virtual Futures",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2017",
                    positions: [
                        {
                            title: "Very Very Far Away: From Dissemination to Anticipation—Democratising Future Narratives",
                            dept: "Andrew Friend, Jasmin Blasco, Sitraka Rakotoniaina",
                            institution: "International Astronautical Federation",
                            location: "Paris, FR"
                        },
                        {
                            title: "Bridging Open Borders — V&A Digital Design Weekend 2017",
                            dept: "Irini Papadimitriou, Andrew Prescott, Jon Rogers",
                            institution: "Uniform Communications",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2014",
                    positions: [
                        {
                            title: "Robot: Artificial Intelligence, Cybernetics and the Machine",
                            dept: "Duncan McCorquodale",
                            institution: "Blackdog Publishing",
                            location: "London, UK"
                        },
                        {
                            title: "Fictions & Anticipation",
                            dept: "Sophie Demay",
                            institution: "Etapes: 218",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2011",
                    positions: [
                        {
                            title: "See Yourself Sensing: Redefining Human Perception",
                            dept: "Madeline Schwartzman",
                            institution: "Blackdog Publishing",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2010",
                    positions: [
                        {
                            title: "EPSRC Impact!",
                            dept: "Kyoko Kakajima",
                            institution: "Axis Magazine",
                            location: "London, UK"
                        }
                    ]
                },
                {
                    yr: "2008",
                    positions: [
                        {
                            title: "Grand Cru 2008",
                            dept: "Caroline Bouige",
                            institution: "Etapes: 161",
                            location: "Paris, FR"
                        }
                    ]
                }
            ]
        }
    ]
}