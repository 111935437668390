import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-css';
import $ from "jquery";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.btnRef = React.createRef();
        this.logoRef = React.createRef();
    }
    handleClick(){
        const wrapper = this.wrapperRef.current;
        const btn = this.btnRef.current;
        const logo = this.logoRef.current;
        // wrapper.classList.toggle('is-nav-open');
        let slideInt = "0";
        if(window.innerHeight > window.innerWidth){
            console.log("portrait");
            slideInt = "100vw";
        }else{
            slideInt = "50vw";
        }
        if($('.App-nav').hasClass('isOpenFromLeft')){

            $('.App-nav').animate({ left: "0"}, 300, function(){
                $('.App-nav').removeClass('isOpenFromLeft');
                $('.App-nav').fadeOut(100);

            });
        }else{
            $('.App-nav').fadeIn(100);
            $('.App-nav').animate({ left: slideInt}, 300, function(){
                
                    $('.App-nav').addClass('isOpenFromLeft');

               
            });
        }
        btn.classList.toggle('rotate-nav');
        // logo.classList.toggle('rotate-nav');
    }
    setInfo(data) {
        this.props.getInfo(data);
        // console.log("go");
        window.scrollTo(0,0);
    }
    render(){
        return(
            <div className="NAV_WRAP">
            <nav ref={this.wrapperRef} className="App-nav wrapper">
                <div className="container jLeft">
                    <ul onClick={()=>this.handleClick()} className="nav-menu">
                        <Masonry 
                            breakpointCols={2}
                            className="masonry-grid"
                            columnClassName="masonry-grid_column"
                        >
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(0)} activeClassName='active-page' to ='/vvfa' exact={true}><img alt="" src={window.location.origin + this.props.pages[0].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(1)} activeClassName='active-page' to ='/through-this-darkness' exact={true}><img alt="" src={window.location.origin + this.props.pages[1].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(2)} activeClassName='active-page' to ='/familiar-unknown' exact={true}><img alt="" src={window.location.origin + this.props.pages[2].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(3)} activeClassName='active-page' to ='/an-inventory' exact={true}><img alt="" src={window.location.origin + this.props.pages[3].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(4)} activeClassName='active-page' to ='/reimagining-outer-space' exact={true}><img alt="" src={window.location.origin + this.props.pages[4].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(5)} activeClassName='active-page' to ='/imagine-if' exact={true}><img alt="" src={window.location.origin + this.props.pages[5].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(6)} activeClassName='active-page' to ='/sightseer' exact={true}><img alt="" src={window.location.origin + this.props.pages[6].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(7)} activeClassName='active-page' to ='/another-excellent-waiting-room' exact={true}><img alt="" src={window.location.origin + this.props.pages[7].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(8)} activeClassName='active-page' to ='/wind-concert' exact={true}><img alt="" src={window.location.origin + this.props.pages[8].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(9)} activeClassName='active-page' to ='/prophecy-program' exact={true}><img alt="" src={window.location.origin + this.props.pages[9].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(12)} activeClassName='active-page' to ='/hyper-normal' exact={true}><img alt="" src={window.location.origin + this.props.pages[12].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(11)} activeClassName='active-page' to ='/shocking' exact={true}><img alt="" src={window.location.origin + this.props.pages[11].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(10)} activeClassName='active-page' to ='/photon-shower' exact={true}><img alt="" src={window.location.origin + this.props.pages[10].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(13)} activeClassName='active-page' to ='/interplay' exact={true}><img alt="" src={window.location.origin + this.props.pages[13].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(14)} activeClassName='active-page' to ='/dmax' exact={true}><img alt="" src={window.location.origin + this.props.pages[14].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(15)} activeClassName='active-page' to ='/poundshop' exact={true}><img alt="" src={window.location.origin + this.props.pages[15].img} /></NavLink></li>
                        <li className="nav-items" ><NavLink info={this.props.info} onClick={()=>this.setInfo(16)} activeClassName='active-page' to ='/cree-connected-lamp' exact={true}><img alt="" src={window.location.origin + this.props.pages[16].img} /></NavLink></li>
                        </Masonry>
                    </ul>
                </div>
                
            </nav>
            <div ref={this.btnRef} className="nav-btn btn" onClick={()=>this.handleClick()}>INDEX</div>
            {/* <div ref={this.logoRef} className="logo"onClick={()=>this.handleClick()}>INDEX</div> */}
            </div>
        );
    }
};

function mapStateToProps(state){
    return {
        pages: state.Pages
    };
}

export default connect(mapStateToProps)(Navbar);