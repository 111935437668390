import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class About extends Component {
    
    state = {
        img_src: this.props.pages[0].img, 
        img_id: this.props.pages[0].id,
        img_title: "", 
        img_url: "",
        counter: 0,
        intervalId: ""
    }

    componentDidMount(){
        var intervalId = setInterval(() => {
            this.timer();
        }, 1000);
        this.setState({intervalId: intervalId});
    }    
 
    componentWillUnmount(){
        clearInterval(this.state.intervalId);
    }

    setInfo(data) {
        this.props.getInfo(data);
    }

    timer(){
        this.setState({ counter: this.state.counter +1 });
        if( this.state.counter > this.props.pages.length -1 ){
            this.setState({ counter: 0 });  
        }
        this.setState({ img_src: this.props.pages[this.state.counter].img, img_title: this.props.pages[this.state.counter].title });
        // console.log(this.props.location.pathname);
    }

    redirectToUrl = () => {
        const { history } = this.props;
        if(history){
            history.push(this.props.pages[this.state.counter].url);
            this.setInfo(this.state.counter);
            // console.log(this.props.pages[this.state.counter].url);
        } 
    }
    

    render(){
        
        return(
            <div className="about-wrapper">
            <div className="about-container">
                <div className="section">
                    <p className="section-title">{ this.props.abouts[2].title }</p>
                    <p className="about-txt">{ this.props.abouts[2].content }</p>
                    <p className="section-title">{ this.props.abouts[5].title }</p>
                    <div className="about-txt">{ 
                    this.props.abouts[5].content.map((yr, index) => {
                        return  <div key={index}>
                                    <p className="yr">_<br/>{yr.yr}</p>
                                    <div>{
                                        yr.positions.map((pos, index) => {
                                            return  <ul key={index}>
                                                        <li className="pos-title">{pos.title}</li>
                                                        {/* <li className="dept">{pos.dept}</li> */}
                                                        <li className="institution">{pos.institution}</li>
                                                        <li className="pos-location">{pos.location}</li>
                                                    </ul>
                                        })}
                                    </div>
                                </div>
                    }) }</div>
                    <p className="section-title">{ this.props.abouts[6].title }</p>
                    <div className="about-txt">{ 
                    this.props.abouts[6].content.map((yr, index) => {
                        return  <div key={index}>
                                    <p className="yr">_<br/>{yr.yr}</p>
                                    <div>{
                                        yr.positions.map((pos, index) => {
                                            return  <ul key={index}>
                                                        <li className="pos-title">{pos.title}</li>
                                                        <li className="dept">{pos.dept}</li>
                                                        <li className="institution">{pos.institution}</li>
                                                        <li className="pos-location">{pos.location}</li>
                                                    </ul>
                                        })}
                                    </div>
                                </div>
                    }) }</div>
                </div>
                <div className="section">
                    <p className="section-title">{ this.props.abouts[3].title }</p>
                    <div className="about-txt">{ 
                    this.props.abouts[3].content.map((yr, index) => {
                        return  <div key={index}>
                                    <p className="yr">_<br/>{yr.yr}</p>
                                    <div>{
                                        yr.positions.map((pos, index) => {
                                            return  <ul key={index}>
                                                        <li className="pos-title">{pos.title}</li>
                                                        <li className="dept">{pos.dept}</li>
                                                        <li className="institution">{pos.institution}</li>
                                                        <li className="pos-location">{pos.location}</li>
                                                    </ul>
                                        })}
                                    </div>
                                </div>
                    }) }</div>
                </div>
                <div className="section">
                    <p className="section-title">{ this.props.abouts[4].title }</p>
                    <div className="about-txt">{ 
                    this.props.abouts[4].content.map((yr, index) => {
                        return  <div key={index}>
                                    <p className="yr">_<br/>{yr.yr}</p>
                                    <div>{
                                        yr.positions.map((pos, index) => {
                                            return  <ul key={index}>
                                                        <li className="pos-title">{pos.title}</li>
                                                        <li className="Edept">{pos.dept}</li>
                                                        <li className="Einstitution">{pos.institution}</li>
                                                        <li className="pos-location">{pos.location}</li>
                                                    </ul>
                                        })}
                                    </div>
                                </div>
                    }) }</div>
                </div>
            </div>
            </div>
        );
    };
}

function mapStateToProps(state){
    return {
        pages: state.Pages,
        abouts: state.Abouts
    };
}

export default withRouter(connect(mapStateToProps)(About));