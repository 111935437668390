import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import $ from "jquery";


class InfoBar extends Component {
    constructor(props){
        super(props);
        this.wrapperRef = React.createRef();
        this.btnRef = React.createRef();
        this.logoRef =React.createRef();        
    }
    state = {
        isHidden: false
    }
    transCallback = () => {
        if(!this.wrapperRef.current.classList.contains('is-info-open')){
            this.setState({isHidden:true});
            //$('.App-info').hide();
            // this.wrapperRef.current.classList.toggle('hideEl');
        }

    }
    handleClick(){
        const wrapper_i = this.wrapperRef.current;
        const btn_i = this.btnRef.current;
        const logo_i = this.logoRef.current;
        let slideInt = "0";
        if(window.innerHeight > window.innerWidth){
            console.log("portrait");
            slideInt = "-100vw";
        }else{
            slideInt = "-50vw";
        }
        if($('.App-info').hasClass('isOpenFromRight')){

            $('.App-info').animate({ right: slideInt}, 300, function(){
                $('.App-info').removeClass('isOpenFromRight');
                $('.App-info').fadeOut(100);

            });
        }else{
            $('.App-info').fadeIn(100);
            $('.App-info').animate({ right: "0"}, 300, function(){
                
                    $('.App-info').addClass('isOpenFromRight');

               
            });
        }
        
        btn_i.classList.toggle('rotate-info');
        // logo_i.classList.toggle('rotate-info');
    }
    componentDidMount(){
        this.wrapperRef.current.addEventListener("webkitTransitionEnd", this.transCallback);
        // this.wrapperRef.current.addEventListener("transitionEnd", this.transCallback);
    }
    render(){
        const abouts = () => {
            var d = this.props.abouts[0].content;
            var cont = d.replace("\\n", "\n");
            if(this.props.location.pathname === "/"){
                return <div className="txt_content wrapper_i" >
                            <p>{this.props.abouts[0].title}</p>
                            <p>{cont}</p>
                        </div>
            }else if(this.props.location.pathname === "/about"){
                return <div className="txt_content wrapper_i" >
                            <p>{this.props.abouts[0].title}</p>
                            <p>{this.props.abouts[0].content}</p>
                        </div>
            }else{
                return <div className="txt_content wrapper_i">
                            <p>{this.props.pages[Number(this.props.info)].title}</p>
                            <p>{this.props.pages[Number(this.props.info)].content}</p>
                        </div>
            }
        }
        return(
            <div className="INFO_WRAP">
                
            <div ref={this.wrapperRef} className="App-info wrapper_i" >
                
                <div ref={this.wrapperRef} className="container jLeft wrapper_i" onClick={()=>this.handleClick()} >
                        {abouts()}
                </div>
                {/* <div>{this.props.info}</div> */}
                
            </div>
            
            <div ref={this.btnRef} className="info-btn btn_i" onClick={()=>this.handleClick()} >INFO</div>
            {/* <div ref={this.logoRef} onClick={()=>this.handleClick()} className="infoLogo">INFO</div> */}
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        pages: state.Pages,
        abouts: state.Abouts
    };
}
export default withRouter(connect(mapStateToProps)(InfoBar));