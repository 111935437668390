import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from '@reduxjs/toolkit';
import MainReducers from './components/reducers/reducersIndex';
import Navbar from './components/layout/navbar';
import InfoBar from './components/layout/infobar';
import Container from './components/utils/container';
import Menu from './components/layout/menu';
import './App.css';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const store = createStore(MainReducers);

class App extends Component {
  state = { info: "0" };
 
 constructor(props){
   super(props);
   this.InfoBarRef = React.createRef();
   this.body = document.querySelector('body');
  //  disableBodyScroll(this.body);
   document.body.style.overflow = "hidden";
 }

 updateInfo(data) {
  //  console.log(data);
  //  console.log("updated");
   this.setState({ info: data })
 }
 render(){
   return (
     <Provider store={store}>
       <BrowserRouter>
         <div className="App">
           <Navbar getInfo={this.updateInfo.bind(this)} info={this.state.info}/>
           <InfoBar info={this.state.info}/>
           <Menu />
           <Container info={this.state.info} getInfo={this.updateInfo.bind(this)}/>
         </div>
       </BrowserRouter>
     </Provider>
   );
 }
}

export default App;
