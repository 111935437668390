export default function() {
    return[
        {
            id: 0, 
            title: "Very Very Far Away", 
            img: "/assets/Thumbs/vvfa_thumb.jpg",
            clientId: "client_id=aae0cd3ce269784234bb78aa6d485394",
            url: "/vvfa",
            dir: "/assets/projects/vvfa",
            files: [["https://player.vimeo.com/video/381883246?title=0&byline=0&portrait=0","'EP.2 The Great Rupture' Teaser for the 3rd episode of VVFA radio play."],["vvfa_04.jpg", "'Objects as Monuments' workshop w/ the students of Media Design Practices at Art Center College of Design, Pasadena, CA."],["https://player.vimeo.com/video/200685709?title=0&byline=0&portrait=0", "'EP.0 Moonshiners' Teaser for the first episode of VVFA radio play"], ["vvfa_12.jpg", "'Coming Soon' workshop w/ School of Machines, Casa Jasmina & Officine Arduino in Turin, Italy (image: 'The Empathy Bomber' by Monique Grimord)"], ["https://player.vimeo.com/video/226031882?title=0&byline=0&portrait=0", "'EP.1 Digital Ghost' Teaser for the 2nd episode of VVFA radio play."], ["vvfa_09.jpg", "The Extreme Land, Default 17 workshop w/ artists in residence of Ramdom Association in Gagliano del Capo, Italy."],["vvfa_01.jpg", "'Fabricating Fictions' workshops w/ Space Studios, Art & Technology in London, UK"],["vvfa_07.jpg", "'Fabricating Empathy' workshop w/ School of Machines & ACUD in Berlin, Germany"],["vvfa_15.jpg", "'If You Don't Organise Yourselves You Will Be Organised' workshop w/ 3rd year BA Design students at Goldsmiths University, London, UK"]],
            vid: false,
            content: "Very Very Far Away (VVFA) is a public facing research project exploring the democratisation of future narratives. VVFA consists of a podcast, a series of co-enquiries/workshops, and special projects.\n\nWe use design as an exploratory tool to depict the possibilities associated with new and emerging technologies, through collaborative world building and reverse archaeology; \n\nVVFA uses Space Exploration as a lens to examine current ideas and values which may pertain to future societies through technological advances. We examine and critique the cultural and social implications of these future aspirations, whilst reflecting heavily on the present status quo.\n\nVVFA explores new modes of creative collaboration between artists, technologists, scientists and partner institutions worldwide, by bringing diverse audiences together and examining multiple perspectives at once. VVFA engages and empowers audiences both in the creation and the dissemination of new cultural fictions."
        },
        {
            id: 1, 
            title: "Through This Darkness", 
            img: "/assets/Thumbs/through-this-darkness_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/through-this-darkness",
            dir: "/assets/projects/through-this-darkness",
            files: [["https://player.vimeo.com/video/333963370?title=0&byline=0&portrait=0", "'Through this Darkness' by Tony Njoku w/ Boiler Room, 4:3 Short"], ["ttd_01.jpeg", "Face generation samples made from a dataset of faces taken from google image and specially made portrait from Tony Njoku"],["ttd_01b.jpeg", "Face generation samples"],["ttd_01c.jpeg", "Face generation samples"],["ttd_02.png", "Failing face tracking"],["https://player.vimeo.com/video/333970332?title=0&byline=0&portrait=0", "Slit scan tests"]],
            vid: false,
            content: "A series of visual effects for Tony Njoku's music video, directed by Tee Byford and created using machine learning:\nYolo for object detection, and DCGAN for face generation.\n\nA visual exploration of a fully quantified world, where even identities are being quantized, eventually leading to the disappearance of what cannot be measured.\n\nFrom Tee Byford: 'Using AI the film explores the inherently bias nature of Artificial intelligence, Amongst the lyrical narratives it produces an unnerving commentary on the racial prejudices embedded in today’s emerging technologies'"
        },
        {
            id: 2, 
            title: "A Familiar unknown", 
            img: "/assets/Thumbs/familiar-unknown_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/familiar-unknown",
            dir: "/assets/projects/familiar-unknown",
            files: [["fu_01.jpg", "Detail, 3 way Speaker at Jupiter Woods"], ["FU_01b.jpg", "3 way Speaker at Jupiter Woods"],["fu_02.jpg", "Detail of a one of the 9 control boxes at Jupiter Woods"],["fu_03.jpg", "9 people synthesiser players at Jupiter Woods"],["fu_04.jpg", "Detail, at Jupiter Woods"],["fu_05.jpg", "Synthesiser detail on pedestal at Tate Britain"],["fu_06.jpg", "Audience writing their piece before performing at the Tate britain"],["https://player.vimeo.com/video/464753744", "Audio exerpt recorded at the Tate Britain"]],
            vid: false,
            content: "A 9 people synthesiser played through a central 3 way speaker, designed as part of a storytelling ritual.\n\nA Familiar Unknown: Part 1, was first presented at Jupiter Woods Gallery, at the end of 2019. As part of the autumn series of Forest Rangers, organised by Disapore.\n\nForest Rangers is a reading group exploring the current state of the commons and their possible future. VVFA reading sessions were particularly focused on discussing the commons in the context of outer space;\n\nSpace exploration is a perspective-altering endeavour that has (literally and figuratively) shaped worldviews and cultures, and provided a new framework to explore the narrative construct of humanity’s future.\n\nThese reading sessions aimed to examine the existing treaties and rules shaping the collective imagination in order to propose more “democratic” alternatives, and culminate in the performance of a proposed utopia.\n\nA Familiar Unknown: Part 2, invited visitors to realise their collective agency through collaborating in a new sonic and spatial performance within the Tate Britain, and as part of 'From Tomorrow'.\n\nThrough collaborative performance and creation we want to embody the agency of the individual as protagonists in their own futures, but also, more importantly as agents for a larger collective future."
        },
        {
            id: 3, 
            title: "An Inventory of Objects\nThat Haven't Been Invented Yet!", 
            img: "/assets/Thumbs/an-inventory_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/an-inventory",
            dir: "/assets/projects/an-inventory",
            files: [["not_01.jpg", "'God Hates E-minds', demonstration materials by Ivana Jovic, Romanos Theodoridis & Despina Simeonidou Masterstudio, Basel, 2016"], ["not_02.jpg", "'Data heirloom' by Andrea Züllig, Gabriela Mighali Masterstudio, Basel, 2016"],["not_03.jpg", "'Future Healines' by Ivana Jovic, Romanos Theodoridis & Despina Simeonidou Masterstudio, Basel, 2016"],["not_04.jpg", "'Being Social', a future guidebook for human interaction by Matthias Maurer, Manon Briod & Sophia Klinkenberg Masterstudio, Basel, 2016"],["not_05.jpg", "'Cai-borg' by Daniel Nikles & Debolina Dubois Bandyopadhyay Masterstudio, Basel, 2016"],["not_06.jpg", "'Being Social' by Matthias Maurer, Manon Briod & Sophia Klinkenberg Masterstudio, Basel, 2016"],["not_07.jpg", "'Arm Farm' by Aline Minder, Jan Kristof Nemeth & Samuele Tirendi Masterstudio, Basel, 2016"]],
            vid: false,
            content: "Working with 30 students from the Institute Integrative Design, MasterStudio, at the University of Northwestern Switzerland in Basel over two sessions spread over two months, we developed a very detailed set of contexts, narratives and worlds which were firstly rendered into a series of radio plays.\n\n These were used to imagine, identify and design the iconic objects, which were built as props for the creation of a photographic ‘archive of objects that haven’t been invented (yet)’. The resulting projects included:\n\n'God Hates E-minds' explores a future where consciousness is stored digitally when your physical body passes away, the project examined the storage and ownership of personal data, from possible individual, activist and corporate perspectives.\n\n'Being Social' examines a future where human interactions have become more and more isolated and introvert, the guidebook for social interactions helps to counter this shift, offering useful advice, parameters and instruction for being more social.\n\n'Cai-borg' is a story about a machine who may have learnt too much. Sitting within its workshop home a previously un-gendered robotic arm takes cues from its physical surroundings and information it learnt online to move from 'it' to 'he'.\n\n'Arm Farm' provides a solution to environmental concerns, through slightly modifying one’s skin it could be possible to grow air filtration ‘farm’s’ to provide fresh air for respiration in an increasingly polluted world."
        },
        {
            id: 4, 
            title: "Re-imagining Outer Space", 
            img: "/assets/Thumbs/reimagining-outer-space_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/reimagining-outer-space",
            dir: "/assets/projects/reimagining-outer-space",
            files: [["https://player.vimeo.com/video/380256447?title=0&byline=0&portrait=0", "Re-Imagining Outer Space, projects trailer"], ["02.jpeg", "'Dying on the moon' Cardboard prototype of a space helmet / euthanasia device"],["03.jpeg", "Detail cardboard 1:1 set, spacecraft laboratory"],["04.jpeg", "Detail set, seen through a porthole"],["05.jpeg", "Detail set, Moon hotel reception desk"]],
            vid: false,
            content: "“Re-imagining Outer Space” is a collaboration between artists and designers Joseph Popper, Sitraka Rakotoniaina and design students from HEAD University in Geneva.\n\nOnly 12 men have stood on the Moon and only around 540 people have ever been to space. For the rest of us, experiencing this outer space perspective depends on imagery and fiction, poetic leaps and artistic choices.\n\nAnd yet, concerning outer space exploration and industry today, there remains a singular, dominating, Apollo-era imagination of what space and humanity are. How can this be disrupted, and how can human futures in outer space and on Earth be reimagined in new ways?\n\n With guidance from Popper and Rakotoniaina, the students accomplished a range of design processes to imagine, prototype and produce their personal visions of human futures in outer space.\n\nThe films center on future space characters developed by the students, each interpreting possible motivations to go to space: such as survival, progress, profit or environmental concerns. The imagined scenarios were materialised in different media, from building sets and making props at 1:1 scale."
        },
        {
            id: 5, 
            title: "Imagine If", 
            img: "/assets/Thumbs/imagine-if_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/imagine-if",
            dir: "/assets/projects/imagine-if",
            files: [["01.jpg", "Dual/stereo microphone for capturing stories"],["03.jpg", "Table/Map detail of future timelines"],["04.jpg", "Future mapping through drawing and collaging"],["05.jpg", "Detail helmet / microphone"],["06.jpg", "Early detail, of the table/map"]],
            vid: false,
            content: "Imagine If, a performance and installation created for London’s Tate Britain during the Tate and Institute of Imagination’s Festival of imagination\n\nThrough co-creation, visitors were invited to imagine firstly what they might like the future to be like, and then develop how pieces of it may look, and finally how it might sound. Visitors ranging from 3 years old to 70+, collaboratively drew a combination of chaotic, fantastic and terrifying futures.\n\nThe resulting stories were captured using a specially sculpted binaural microphone, located in the gallery, forming an hourly sonic performance."
        }, 
        {
            id: 6, 
            title: "Sightseer", 
            img: "/assets/Thumbs/sightseer_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/sightseer",
            dir: "/assets/projects/sightseer",
            files: [["01.jpg", "'WORMS' preparing for the live broadcast"],["02.jpg", "Visitors improvising stories in response to WORMS improvised soundscapes"],["03.jpg", "Detail of WORMS' music engine"]],
            vid: false,
            content: "Sightseer is an experimental participatory performance, originally conceived as part of Very Very Far Away (VVFA) inaugural live radio broadcast at the Victoria & Albert museum, during the Digital Design Weekend of 2017.\n\nThe performance invited audience members to create improvised dialogue over a live soundscape, inhabiting roles set out by VVFA on the fictional island ‘Geospiza’.\n\nGeospiza is the planetary base for a space elevator, inspired by Soviet rocket scientist Konstantin Tsiolkovsky’s 1889 proposal, linking a satellite in geostationary orbit back to earth.\n\nThis put in combination with the rapid developments being made in material sciences forms the basis for an exploration into technology, ownership, class and the psychological epiphany described as the overview effect.\n\nHaving set out the characters and context for a dialogue audience members are placed into conversation with professional actors and invited to improvise moments based before, after and during a fictional power cut in the island’s infrastructure, responding to live audio illustration from electronic band ‘Worms’."
        },
        {
            id: 7, 
            title: "Another Excellent Waiting Room", 
            img: "/assets/Thumbs/another-excellent-waiting-room_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/another-excellent-waiting-room",
            dir: "/assets/projects/another-excellent-waiting-room",
            files: [["01.jpg", "Frontal view of the resting corridor/space from 'Moonshiners', designed with a forced perspective"],["02.jpg", "Sitting area witing the resting alley"],["03.jpg", "Gallery's front room, VR headset and CRT monitor"],["04.jpg", "Detail from the resting alley lighting"],["05.jpg", "VR forest environment detail"],["06.jpg", "CRT screen playing 3D animation and footage from Tahiti's rain forest"],["07.jpg", "Exterior corridor view. Stretched canvas to allow for video projection"]],
            vid: false,
            content: "Another Excellent Waiting Room, was presented at the Nunns Yard Gallery in Norwich, in 2016.\n\nThe multimedia installation ‘Another Excellent Waiting Room’ corresponds with the launch of the pilot episode EP.0 - Moonshiners, of the Very Very Far Away podcast, crafting a combined physical and virtual representation of spaces detailed within the episode of the podcast.\n\nInstallation; Timber, Astroturf, Wallpaper, Electronics, Two channel video and audio, Dimensions Variable, Video 10.00.00 Loop, VR headset, Unity Environment.\n\nInstallation created in collaboration with Jasmin Blasco, Andrew Friend and Michael Lewis."
        },
        {
            id: 8, 
            title: "Wind Concert", 
            img: "/assets/Thumbs/wind-concert_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/wind-concert",
            dir: "/assets/projects/wind-concert",
            files: [["https://player.vimeo.com/video/149756547?title=0&byline=0&portrait=0", "'Making of' video created by Nexus Interactive Arts"],["02.jpg", "Picture taken on the top floor of the Ping An tower, during the construction."],["03.jpg", "Drone view of the building"]],
            vid: false,
            content: "Commissioned by the Ping An Financial Center (PAFC) 'Wind Concert' is a sound installation designed to convert the high speed winds at the top of the tower (600 meters) into music.\n\nAfter simulating birds flocks using 'Processing' the trajectories were translated into curves and used to inform the overall design and geometry of the steel pipes\n\nTheir length as well as the shape of the embouchures have been designed to create varying tones corresponding with those heard in traditional Chinese scales\n\nProject realised with Nexus Interactive Arts"
        },
        {
            id: 9, 
            title: "Prophecy Program", 
            img: "/assets/Thumbs/prophecy-program3_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/prophecy-program",
            dir: "/assets/projects/prophecy-program",
            files: [["https://player.vimeo.com/video/73369193?title=0&byline=0&portrait=0", "Teaser for the 'Prophecy Program' testing apparatus"],["01.jpg", "Test subject facing the air cannons array, hidden behind fiber glass screens"],["02b.jpg", "Testing apparatus as arranged and exhibited in the Media Design Practices wind tunnel gallery"],["02.jpg", "Detail screens back, with cannons valves and compressor"],["05.jpg", "Analog chart recorder, with disposable needles and adjustable lengths"],["06.jpg", "Trigger mechanisms, actuated with randomly inflated air balloons"],["04b.jpg", "Control box for the testing apparatus"],["07.jpg", "Detail control box, front panel"],["08.jpg", "'Waiting Tower' model. This experiment proposes to position an array of towers along the San Andreas fault, whereby remote viewers can monitor the fault's seismic activity."],["09.jpg", "Detail Waiting Tower seat."]],
            vid: false,
            content: "The Prophecy Program is an exploration of the human capabilities in sensing future events, as well as an examination of the nature and aesthetic language of the scientific experiment.\n\nThis project has been produced as part of the Media Design Practices summer research program, and took place within a former NASA wind-tunnel, now converted into studios for the Art Center College of Design.\n\nThe program follows the publication of ‘Feeling the Future’ a paper written by prof. Daryl J. Bem, in 2010, and consists of three experiments, directly influenced by Bem's writings:\n\n Precognition test-rig, Waiting Tower for remote viewing and Bacterial Drone, organised around a set of monitoring apparatus and two purposely built chart recorders.\n\nConversely to a scientific experiment, where the compiled data is of critical importance, the program is interested in the relationship between audience and science, vulgarisation and accurate measurements, emerging from the original claim and intention, and the creation of beliefs and/or scepticism."
        },
        {
            id: 10, 
            title: "Photon Shower", 
            img: "/assets/Thumbs/photon-shower_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/photon-shower",
            dir: "/assets/projects/photon-shower",
            files: [["https://player.vimeo.com/video/65808293?title=0&byline=0&portrait=0", "'Making of' video, created by Nexus Interactive Arts"],["02.jpg", "The booth is cladded with two-way mirrors to give a sense of space."],["03.jpg", "Detail shot of repeated animations"],["05.jpg", "The LED panels is fixed behind 2 layers of two-way mirrors, to give an impresssion of depth."]],
            vid: false,
            content: "The Photon Shower is a light therapy booth designed as a concept product for Delta airline, presented at the TED 2013 conference in long beach, California. It is meant to help the conference attendees to cope with jet lag by immersing them in blue lights animations for a few moments.\n\nThe animations and booth were based on the work of Russell Foster, professor of circadian neuroscience, who made key contributions to the discovery of photosensitive retinal ganglion cells (pRGCs), and their role in regulating our circadian rhythm."
        },
        {
            id: 11, 
            title: "Shocking", 
            img: "/assets/Thumbs/shocking_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/shocking",
            dir: "/assets/projects/shocking",
            files: [["09.jpg", "The 'Impactor' device, designed like an 8 limbed bow to project the built-in lump of steel"],["02.jpg", "Detail of the Impactor's projectile"],["03.jpg", "Close up of the 'Neck clamp', with a small circular piece of nanoceramic in its centre."],["04.jpg", "Close up of the 'Arm clamp', designed to hold the user's arm in place, with a clear target for testing impact"],["05.jpg", "User attaching the 'Finger clamp' before experiencing high impact"],["06.jpg", "Close up of the 'Fincger Clamp'."],["07.jpg", "User wearing the 'Arm clamp'."],["08.jpg", "User wearing the 'Neck clamp', about to be impacted."],["01.jpg", "Detail Impactor and Neck clamp."]],
            vid: false,
            content: "Commissioned by the Engineering and Physical Sciences Research Council, the project is the result of a collaboration between designers Andrew Friend and Sitraka Rakotonianina, and reserachers at Nottingham University, exploring new shock absorbing nano composites; nanoceramics.\n\nIf you could safely experience high impact shock, would you?\n\nDevelopment of new nano-composites opens up new realms of experiential possibilities, pushing both physical and psychological limits. However, despite the rigourous scientific testing and stringient saftey precautions how much faith would you put in this technology, where do the boundaries between thrill, fear and science lie?\n\nOur devices allow individuals the chance to test these limits for themselves, capitalising on these new, fantastic material qualities.\n\nShocking was first exhibited at the impact exhibition at the Royal College of Art and the Cheltenham Science Festival, in 2010."
        },
        {
            id: 12, 
            title: "Hyper-Normal", 
            img: "/assets/Thumbs/hyper-normal_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/hyper-normal",
            dir: "/assets/projects/hyper-normal",
            files: [["https://player.vimeo.com/video/12809872?title=0&byline=0&portrait=0", "Teaser for the project and the general area of research"],["02.png", "Mapping of the hyper-normal and areas of interest."],["01.jpg", "Early 'LEGO' prototype"],["03.jpg", "4th prototype presented at the Victoria and Albert museum"],["04.jpg", "4th prototype presented at the Victoria and Albert museum"],["05.jpg", "The device uses a simple hydrolic system to slow down movements"],["07.jpg", "Beam photographed during the Royal College of Art summer show exhibition"],["06.jpg", "Detail, pump mechanism and counter weight"]],
            vid: false,
            content: "Our brain, constantly juggles with sensorial inputs, re-adjusting, synchronising events to make them feel normal and reasonable. But, what is ‘normal’, if our conscious experience is a made-up 'brain reality'?\n\nThis project explores the possibility of an ‘Hyper-Normal’, where a distorted experience of reality is induced because of physical or psychological stresses, injuries, or training; a space opening up new experiences, potentially providing new insights into people’s condition.\n\nExperiment #1: 'Time Conditioning', aiming at expanding human lifespan whilst observing a certain quality of life. \"If our experience of time was twice slower, we would relatively live twice longer.\"\n\nThis culminated in the creation of a series of ‘handicap objects’ limiting our physical abilities, hoping to force a slowed down perception of time.\n\nThe hypothesis is that, after a period of adaptation, the subject is freed from these contraptions leading to the creation of much denser memories and potentially experiencing a time distortion by feeling that an event lasted longer that it really did.\n\nExperiment #2: 'Beam me down', aiming at giving its users the ability to explore parallel lives.\n\nThe device consists of an air pump hidden, through a trap door, inside a beam. The use of this device would cause hyperventilation potentially leading to a brain ischemia and a temporary loss of memory.\n\n As the user faints and falls on the floor, a counter weight pulls the trap door shut, leaving no evidence.\n\n This would give opportunities to manufacture clues for framing or set oneself up, who, once awake, could explore and experience things as new, until memories come back."
        },
        {
            id: 13, 
            title: "Inter:Play", 
            img: "/assets/Thumbs/interplay_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/interplay",
            dir: "/assets/projects/interplay",
            files: [["01.jpg", "Giant petri dish inoculated with bacteria. The plastic shapes are used like in a strategy board game to follow the spread of each colony."],["03.jpg", "Universal Sleep Machine, calculates whether or not you've had enough sleep."],["04.jpg", "Detail interface of the Universal Sleep Machine"]],
            vid: false,
            content: "Inter-Play is an exhibition of interactive experiences inspired by projects on brain and behaviour, microbial communities and sleep and circadian rhythms.\n\nDesigned to spark curiosity about the research themes and questions, these included a giant petri dish showing the relationship between microbes and a universal sleep machine to optimise your circadian rhythm.\n\nThe exhibition and objects were created under the MILES (Models and Mathematics in Life and Social Sciences) programme, which was conceived as a way of encouraging academics from different disciplines to step out of their comfort zone and collaborate."
        },
        {
            id: 14, 
            title: "DMAX TV Idents", 
            img: "/assets/Thumbs/dmax_thumb.jpeg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/dmax",
            dir: "/assets/projects/dmax",
            files: [["https://player.vimeo.com/video/50493318?title=0&byline=0&portrait=0","DMAX TV idents compilation"],["01.jpg", "Elephant toothpaste, extract from video."],["02.gif", "Ident for engineering oriented shows"],["03.gif", "Ident for nature and fishing shows"],["04.jpg", "Detail set"]],
            vid: false,
            content: "Design and implementation of multiple \"Rube Goldberg-like machines\" for a series of TV idents, directed by George Wu.\n\nThe idents were originally made for DMAX Italia, Discovery Network Western Europe."
        },
        {
            id: 15, 
            title: "Poundshop Cart", 
            img: "/assets/Thumbs/poundshop_thumb.png",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/poundshop",
            dir: "/assets/projects/poundshop",
            files: [["https://player.vimeo.com/video/45242920?title=0&byline=0&portrait=0", "Original Poundshop cart, designed as a one off."],["https://player.vimeo.com/video/233284274?title=0&byline=0&portrait=0","Version 2 designed for batch production and CNC manufacturing."],["03.jpg", "Original car closed"],["01.jpg", "Original cart open"],["02.jpg", "Cart version 2 demonstration"]],
            vid: false,
            content: "Design and fabrication of \"The Poundshop Cart\", a pop up design shop, run by George Wu and Sarah Melin. Following the success of the one off, original design, the cart was re-designed for ease of manufacture and distribution."
        },
        {
            id: 16, 
            title: "Cree's Connected Lamp", 
            img: "/assets/Thumbs/cree-connected-lamp_thumb.jpg",
            clientId: "client_id=2l0GDV0bZbaTxcQI8iMUwDpoYH57Xuia",
            url: "/cree-connected-lamp",
            dir: "/assets/projects/cree",
            files: [["https://player.vimeo.com/video/120139078?title=0&byline=0&portrait=0","'Making of' video created by Nexus Interactive Arts."],["01.jpg", "Detail front panel."]],
            vid: false,
            content: "A connected lamp for American light manufacturer, Cree.\n\nWorking with the oncology department of Duke University Hospital, families and friends of cancer patients, we prototyped a connected lamp designed for non-descriptive modes of communication.\n\nPaired to a smart phone app, users can send drawings and texts to the lamp. Each of theses messages is then interpreted into a colourful light show."
        }
    ]
}