import React, { Component } from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import Home from "../content/home";
import Project from "../layout/project";
import About from "../content/about";

class Container extends Component {
  updateInfo(data) {
    this.props.getInfo(data);
  }

  render(){
    return (
      <Wrapper>

          <Switch>
              <Route exact path="/" render={props => <Home getInfo={this.updateInfo.bind(this)} />} />
              <Route exact path="/about" render={props => <About getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/vvfa" render={props => <Project info="0" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/through-this-darkness" render={props => <Project info="1" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/familiar-unknown" render={props => <Project info="2" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/an-inventory" render={props => <Project info="3" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/reimagining-outer-space" render={props => <Project info="4" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/imagine-if" render={props => <Project info="5" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/sightseer" render={props => <Project info="6" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/another-excellent-waiting-room" render={props => <Project info="7" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/wind-concert" render={props => <Project info="8" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/prophecy-program" render={props => <Project info="9" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/photon-shower" render={props => <Project info="10" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/shocking" render={props => <Project info="11" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/hyper-normal" render={props => <Project info="12" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/interplay" render={props => <Project info="13" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/dmax" render={props => <Project info="14" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/poundshop" render={props => <Project info="15" getInfo={this.updateInfo.bind(this)} />} />
              <Route path="/cree-connected-lamp" render={props => <Project info="16" getInfo={this.updateInfo.bind(this)} />} />
          </Switch>

      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
div.transition-group {
    position: relative;
}
section.route-section {
 position: absolute;
 width: 100%;
 top: 0;
 left: 0;
}

`;

export default Container;