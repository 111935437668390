import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Home extends Component {
    
    state = {
        img_src: this.props.pages[0].img, 
        img_id: this.props.pages[0].id,
        img_title: "", 
        img_url: "",
        counter: 0,
        intervalId: ""
    }

    componentDidMount(){
        var intervalId = setInterval(() => {
            this.timer();
        }, 1000);
        this.setState({intervalId: intervalId});
    }    
 
    componentWillUnmount(){
        clearInterval(this.state.intervalId);
    }

    setInfo(data) {
        this.props.getInfo(data);
    }

    timer(){
        this.setState({ counter: this.state.counter +1 });
        if( this.state.counter > this.props.pages.length -1 ){
            this.setState({ counter: 0 });  
        }
        this.setState({ img_src: this.props.pages[this.state.counter].img, img_title: this.props.pages[this.state.counter].title });
        // console.log(this.props.location.pathname);
    }

    redirectToUrl = () => {
        const { history } = this.props;
        if(history){
            history.push(this.props.pages[this.state.counter].url);
            this.setInfo(this.state.counter);
            // console.log(this.props.pages[this.state.counter].url);
        } 
    }
    

    render(){
        
        return(
            <div onClick={this.redirectToUrl} className="splash" >
            {/* <div onClick={this.redirectToUrl} className="splash" style={{ backgroundImage: "url(" + window.location.origin + this.state.img_src + ")" }} > */}
                <div className="splashImage"style={{ backgroundImage: "url(" + window.location.origin + this.state.img_src + ")" }}></div>
                <div className="splash-title">{ this.state.img_title }</div>
            </div>
        );
    };
}

function mapStateToProps(state){
    return {
        pages: state.Pages
    };
}

export default withRouter(connect(mapStateToProps)(Home));